import { Chart } from 'react-google-charts';

export function BarVertical({ novoValor }) {
    const options = {
        colors: ['#015641', '#16A085', '#58D68D', '#2980B9', '#5DADE2', '#6C3483'],
        legend: {
            position: 'top',
            alignment: 'end',
            textStyle: {
                color: '#015641',
                fontSize: 14
            } //16A085
        },
        bars: 'vertical',
        bar: { groupWidth: '30%' },
        chartArea: {
            top: 20,
            bottom: 0,
            width: '80%',
            height: '100%'
        },
        hAxis: {
            textPosition: 'none'
        },
        vAxis: {
            format: "#'%'"
        }
    };

    console.log(novoValor);

    return <Chart chartType="ColumnChart" width="100%" height="90%" data={novoValor} options={options} />;
}
