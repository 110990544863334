import { Grid } from '@material-ui/core';
import TableGridFarm from '../../components/TableGridFarm';
import { Container } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { BarVertical } from '../../components/BarVertical';
import { BarPie } from '../../components/BarPie';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import { v4 as uuidv4 } from 'uuid';
import Loading from '../../components/Loading';
import { getCaracteristica } from '../../services/bi';
import { agrupaSimplesPercentual, preparaDadosChartPie, removerAcentos } from './functions';
import TelaB from '../../components/TelaB';
import Botones from '../../components/Buttom2';
import Buttom from '../../components/Buttom';
import { Link } from 'react-router-dom';
import { CountertopsOutlined } from '@mui/icons-material';
import { gridColumnsTotalWidthSelector } from '@mui/x-data-grid';

const useStyles = makeStyles({
    list: {
        width: 450
    },
    fullList: {
        width: 'auto'
    }
});

function Caracteristica() {
    const css = useStyles();
    // const [allData, setAllData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [buscaColunaParametro, gravaColunaParametro] = useState([]);
    const [dadosParametro, setDadosParametro] = useState([]);
    const [dadosPie1, setDadosPie1] = useState([]);
    const [dadosPie2, setDadosPie2] = useState([]);
    const [dadosGrid, setDadosGrid] = useState([]);
    const [totalArea, setTotalArea] = useState([]);
    const [atividades, setAtividades] = useState([]);
    const [filterActivitys, setFilterActivitys] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [dados, setDados] = useState([]); //dados principais

    function clearFilter() {
        setFilterActivitys('');
        setFilteredData(dados);
    }

    const dispatch = useDispatch();

    dispatch({ type: 'SET_FILTER_LOADING', payload: isLoading });

    const searchParams = new URLSearchParams(location.search);
    const filterInitialFarm = searchParams.get('fazenda');
    const filterInitialHarvest = searchParams.get('safra');

    const loading = useSelector((state) => state.caracteristica.loading);

    function dataTotal(data) {
        let datax = [...data];
        return setDados(datax);
    }
    function filterByActivitys(ActivitysValue) {
        console.log('a------', dados);
        let dataFil = [...dados]; // make a copy of the original data
        if (ActivitysValue) {
            // check if the filter parameter is defined
            dataFil = dataFil.filter((row) => {
                return row.atividade === ActivitysValue;
            });
        }
        setFilteredData(dataFil);
        return dataFil; // return the filtered data
    }
    console.log(filteredData);

    function preparaDadosParametrosBarVer(data) {
        const dadosAgrupados = agrupaSimplesPercentual(data, 'parametro', 'area');
        let nomeCampos = [];
        let valorCampos = [];
        let atividadCampos = [];
        let chartData = [];
        nomeCampos.push('Local');
        valorCampos.push('%');
        for (const chave in dadosAgrupados) {
            let parametros = dadosAgrupados[chave];
            nomeCampos.push(chave);
            valorCampos.push(parametros.percentual + 0);
        }
        chartData.push(nomeCampos);
        chartData.push(valorCampos);
        setDadosParametro(chartData);
    }

    function preparaDadosParametrosBarPie1(data) {
        let dadosComCategoria = data.map((x) => {
            return {
                ...x,
                categoria: x.grupo_planta === 1 ? '3000 pl/ha' : x.grupo_planta === 2 ? '3000-4000 pl/ha' : '4000-5000 pl/ha'
            };
        });
        let dadosAgrupados = agrupaSimplesPercentual(dadosComCategoria, 'categoria', 'area');
        setDadosPie1(preparaDadosChartPie(dadosAgrupados));
    }

    function preparaDadosParametrosBarPie2(data) {
        let dadosAgrupados = agrupaSimplesPercentual(data, 'tipo', 'area');
        setDadosPie2(preparaDadosChartPie(dadosAgrupados));
    }

    function atividad(data) {
        const actividades = [];
        const actividadesReg = {};
        data.forEach((dat) => {
            if (!actividadesReg[dat.atividade]) {
                actividades.push(dat.atividade);
                actividadesReg[dat.atividade] = true;
            }
        });
        setAtividades(actividades);
    }

    function agrupaComposto(data, chaves) {
        let dd = data.reduce((acc, curr) => {
            const chave = chaves.map((chave) => curr[chave]).join('-');
            acc[chave] = acc[chave] || { fazenda: curr.fazenda, local: curr.local, area: curr.area, atividade: curr.atividade };
            return acc;
        }, {});
        return dd;
    }
    function compararFazendas(fazendaA, fazendaB) {
        let resp = 0;
        if (removerAcentos(fazendaA.fazenda.toUpperCase()) < removerAcentos(fazendaB.fazenda.toUpperCase())) {
            resp = -1;
        } else {
            if (removerAcentos(fazendaA.fazenda.toUpperCase()) > removerAcentos(fazendaB.fazenda.toUpperCase())) {
                resp = 1;
            } else {
                resp = removerAcentos(fazendaA.local.toUpperCase()) < removerAcentos(fazendaB.local.toUpperCase()) ? -1 : 1;
            }
        }
        return resp;
    }
    const generateForm = [
        {
            //data: ['All', ...atividades],
            data: [...atividades],
            onChange: setFilterActivitys,
            value: filterActivitys,
            label: 'Atividade',
            multiSelect: false,
            disabled: false
        }
    ];
    function preparaDadosGrid(data) {
        let dd2 = agrupaComposto(data, ['fazenda', 'local', 'area', 'atividade']);
        let fazendas = [];
        for (const chave in dd2) {
            let auxFazenda = dd2[chave];
            let regFazenda = {
                id: uuidv4(),
                fazenda: auxFazenda.fazenda,
                local: auxFazenda.local,
                area: auxFazenda.area * 1,
                atividade: auxFazenda.atividade
            };
            fazendas.push(regFazenda);
        }
        fazendas.sort(compararFazendas);
        setDadosGrid(fazendas);
        const total = parseFloat(fazendas.reduce((acc, curr) => acc + curr['area'] * 1, 0)).toFixed(2);
        setTotalArea(total);
    }

    function warehouseValues(value) {
        dataTotal(value.data);
        //buscando os dados originais e montando na tela
        preparaDadosParametrosBarVer(value.data);
        preparaDadosParametrosBarPie1(value.data);
        preparaDadosParametrosBarPie2(value.data);
        //Carrega o gride
        preparaDadosGrid(value.data);
        //Carrega a relação das atividades
        atividad(value.data);
    }
    useEffect(() => {
        getCaracteristica(filterInitialFarm, filterInitialHarvest).then((resp) => {
            warehouseValues(resp);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        // console.log('Filtro', filterActivitys);
        if (filterActivitys) {
            const dataFilter = filterByActivitys(filterActivitys);
            preparaDadosParametrosBarVer(dataFilter);
            preparaDadosParametrosBarPie1(dataFilter);
            preparaDadosParametrosBarPie2(dataFilter);
        } else {
            setFilteredData(dados);
            preparaDadosParametrosBarVer(dados);
            preparaDadosParametrosBarPie1(dados);
            preparaDadosParametrosBarPie2(dados);
        }
    }, [filterActivitys]);

    return (
        <Container container>
            <Grid item xs={2}>
                <div style={{ color: '#015641', display: 'flex', alignItems: 'left', justifyContent: 'center', height: '10vh' }}>
                    <h1>Características</h1>
                </div>
            </Grid>
            <Grid item xs={3}>
                {generateForm.map((value) => (
                    <>
                        <div
                            style={{
                                color: '#015641',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '10px',
                                marginRight: '10px'
                            }}
                        >
                            <h3 style={{ color: '#015641', alignItems: 'left', marginRight: '10px' }}>{value.label}</h3>
                            <TelaB
                                data={value.data}
                                label={value.label}
                                onChange={value.onChange}
                                value={value.value}
                                style={{ width: '600px' }}
                            />
                        </div>
                    </>
                ))}
            </Grid>
            <Grid item xs={2} style={{ color: '#015641', display: 'flex', alignItems: 'center', justifyContent: 'left', height: '10vh' }}>
                <Buttom label="limpar filtro" onClick={clearFilter} />
            </Grid>
            <Grid item xs={5} container justify="flex-end" alignItems="right">
                <Link to={`/?fazenda=${filterInitialFarm}&safra=${filterInitialHarvest}`}>
                    <HomeIcon style={{ color: '#015641', fontSize: '48px', marginTop: '15px', marginRight: '10px' }} />
                </Link>
            </Grid>
            <Grid container style={{ border: '5px solid #04580d', borderRadius: '20px' }}>
                <Grid item xs={12}></Grid>
                <Grid item xs={3}>
                    <div
                        style={{
                            height: '80vh',
                            margin: '5px',
                            border: '1px solid black',
                            borderRadius: '20px',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <h2 style={{ color: '#015641', margin: '5px' }}>Lavouras</h2>
                        <div
                            style={{
                                height: '80vh',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <TableGridFarm novoValor={dadosGrid} total={totalArea} filterActivitys={filterActivitys} />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={5}>
                    <div
                        style={{
                            height: '80vh',
                            margin: '5px',
                            border: '1px solid black',
                            borderRadius: '20px',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <h2 style={{ color: '#015641', margin: '15px' }}>Parâmetros</h2>
                        <div
                            style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <BarVertical novoValor={dadosParametro} />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <h2 style={{ color: '#015641', margin: '15px', zIndex: 1, position: 'relative' }}>Qtde Plantas/ha</h2>
                    <div
                        style={{
                            height: '28vh',
                            borderRadius: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <BarPie data={dadosPie1} />
                    </div>
                    <h2 style={{ color: '#015641', margin: '15px', zIndex: 1, position: 'relative' }}>Tipo</h2>
                    <div
                        style={{
                            height: '28vh',
                            borderRadius: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <BarPie data={dadosPie2} />
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}
export default Caracteristica;
