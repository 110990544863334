export function createColumns(data) {
    const groupTotals = data.reduce((totals, item) => {
        const key = `${item.fazenda}-${item.local}`;
        if (!totals[key]) {
            totals[key] = { fazenda: item.fazenda, local: item.local, previsto: 0, realizado: 0, area: item.area };
        }
        totals[key].previsto += parseInt(item.qtde_prevista) / parseFloat(item.area);
        totals[key].realizado += parseInt(item.quantidade) / parseFloat(item.area);
        return totals;
    }, {});

    let percentRealizado = 0;
    let totalPrevisto = 0;
    let totalRealizado = 0;
    Object.values(groupTotals).forEach((groupTotal) => {
        totalPrevisto += groupTotal.previsto;
        totalRealizado += groupTotal.realizado;
    });
    if (totalPrevisto > 0) {
        percentRealizado = (totalRealizado / totalPrevisto) * 100;
    }

    const rows = [['Fazenda', 'Previsto/Ha', 'Realizado/Ha']];
    Object.values(groupTotals).forEach((groupTotal) => {
        rows.push([`${groupTotal.fazenda.substr(0, 3)}/${groupTotal.local.substr(0, 7)}`, groupTotal.previsto, groupTotal.realizado]);
    });

    return { rows, percentRealizado };
}

export function createColumnsSummary(data) {
    let qtdePrevistaTotal = 0;
    let quantidadeTotal = 0;
    console.log('qtdePrevistaTotal', qtdePrevistaTotal);
    for (let i = 0; i < data.length; i++) {
        const item = data[i];
        const qtdePrevista = parseInt(item.qtde_prevista);
        const quantidade = parseInt(item.quantidade);

        if (!isNaN(qtdePrevista)) {
            qtdePrevistaTotal += qtdePrevista;
        }

        if (!isNaN(quantidade)) {
            quantidadeTotal += quantidade;
        }
    }

    return {
        qtdePrevistaTotal,
        quantidadeTotal
    };
}

export function createFilter(data, location = []) {
    console.log('dafff', data);
    const allFarmObj = {};
    const allActivityObj = {};
    const allStatusObj = {};
    const allGroupServiceObj = {};
    const allServiceObj = {};
    const allLocalObj = {};

    for (let i = 0; i < data.length; i++) {
        const farm = data[i].fazenda;
        if (!allFarmObj[farm]) {
            allFarmObj[farm] = true;
        }

        const atividade = data[i].atividade;
        if (!allActivityObj[atividade]) {
            allActivityObj[atividade] = true;
        }

        const grupoServico = data[i].grupo_servico;
        if (!allGroupServiceObj[grupoServico]) {
            allGroupServiceObj[grupoServico] = true;
        }

        const servico = data[i].servico;
        if (!allServiceObj[servico]) {
            allServiceObj[servico] = true;
        }

        const status = data[i].status;
        if (!allStatusObj[status]) {
            allStatusObj[status] = true;
        }

        const local = data[i].local;
        if (!allLocalObj[local]) {
            allLocalObj[local] = true;
        }
    }

    const allServico = Object.keys(allServiceObj);
    const allFarm = Object.keys(allFarmObj);
    const allActivity = Object.keys(allActivityObj);
    const allGroupService = Object.keys(allGroupServiceObj);
    const allStatus = Object.keys(allStatusObj);
    const allLocal = location.length > 0 ? location : Object.keys(allLocalObj);

    const filter = { allFarm, allActivity, allStatus, allGroupService, allLocal, allServico };

    return filter;
}
