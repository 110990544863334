import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Buttom from '../../components/Buttom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Container, Card } from './styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FormDrawer from '../../components/FormDrawer';
import { v4 as uuidv4 } from 'uuid';
import { createFilter, createColumns, createColumnsSummary } from './functions';
import Loading from '../../components/Loading';
import { Chart } from 'react-google-charts';
import { DataGrid } from '@mui/x-data-grid';
import './styled.css';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import MultipleCheckbox from '../../components/MultipleCheckbox';
import gifGrafico from '../../assets/gifGrafico.gif';

const useStyles = makeStyles({
    list: {
        width: 450
    },
    fullList: {
        width: 'auto'
    }
});

export const options = {
    colors: ['#4b7940', '#8ab97c'],
    vAxis: {
        title: 'Values',
        ticks: 5,
        minValue: 0,
        maxValue: 100
    }
};

export default function MoPrevistaRealizada() {
    var dataMoPrevistaRealizada = useSelector((state) => state.allData.allDataMoPrevistaRealizada);

    if (dataMoPrevistaRealizada === null) {
        const serializedStateMoPrevistaRealizada = localStorage.getItem('dataMoPrevistaRealizada');
        if (serializedStateMoPrevistaRealizada !== null) {
            dataMoPrevistaRealizada = JSON.parse(serializedStateMoPrevistaRealizada);
        } else {
            history.push('/home');
        }
    }

    const css = useStyles();
    const _ = require('lodash');

    const searchParams = new URLSearchParams(location.search);
    const filterInitialFarm = searchParams.get('fazenda');
    const filterInitialHarvest = searchParams.get('safra');

    const [allData, setAllData] = useState([]);
    const [allDataSummary, setAllDataSummary] = useState([]);
    const [allFilter, setAllFilter] = useState([]);
    const [filterFarm, setFilterFarm] = useState([]);
    const [filterActivitys, setFilterActivitys] = useState([]);
    const [filterGroupService, setFilterGroupService] = useState([]);
    const [filterStatus, setFilterStatus] = useState([]);
    const [filterLocal, setFilterLocal] = useState([]);
    const [filterServices, setFilterServices] = useState([]);

    const [search, setSearch] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [allDataOriginal, setAllDataOriginal] = useState([]);
    const [percentRealized, setPercentRealized] = useState(0);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const generateForm = [
        {
            data: allFilter.allFarm,
            onChange: setFilterFarm,
            value: filterFarm,
            label: 'Fazenda',
            multiSelect: false,
            disabled: false
        },
        {
            data: allFilter.allLocal,
            onChange: setFilterLocal,
            value: filterLocal,
            label: 'Local',
            multiSelect: true,
            labelHelper: 'Selecione uma fazenda',
            disabled: filterFarm.length <= 0 ? true : false
        },
        {
            data: allFilter.allStatus,
            onChange: setFilterStatus,
            value: filterStatus,
            label: 'Status',
            multiSelect: true,
            disabled: false
        },
        {
            data: allFilter.allActivity,
            onChange: setFilterActivitys,
            value: filterActivitys,
            label: 'Atividade',
            multiSelect: true,
            disabled: false
        }
    ];

    function clearFilter() {
        setFilterFarm([]);
        setFilterActivitys([]);
        setFilterGroupService([]);
        setFilterStatus([]);
        setFilterLocal([]);
        setFilterServices([]);
        setSearch(!search);
    }

    function warehouseValues(value) {
        setAllDataOriginal(value.data);

        const dataFormated = createColumns(value.data);
        const dataSummary = createColumnsSummary(value.data);
        setAllDataSummary(dataSummary);
        setAllFilter(createFilter(value.data));
        setAllData(dataFormated.rows);
        setPercentRealized(dataFormated.percentRealizado);
    }

    function filterData() {
        const filteredDataLocationFarm = allDataOriginal.filter((obj) => obj.fazenda === filterFarm);
        const uniqueLocais = filteredDataLocationFarm.reduce((acc, obj) => {
            acc[obj.local] = true;
            return acc;
        }, {});
        const newFilteredLocais = Object.keys(uniqueLocais);

        const filteredDataService = allDataOriginal.filter((obj) => filterGroupService.includes(obj.grupo_servico));

        const uniqueServices = filteredDataService.reduce((acc, obj) => {
            acc[obj.servico] = true;
            return acc;
        }, {});
        const newFilteredServices = Object.keys(uniqueServices);

        setAllFilter((prevFilter) => ({ ...prevFilter, allLocal: newFilteredLocais, allServico: newFilteredServices }));
        const filteredRows = _.filter(allDataOriginal, (row) => {
            const propsToCheck = ['status', 'activitys', 'groupService', 'farm', 'local', 'service'];
            const filters = {
                status: _.isEmpty(filterStatus) || _.includes(filterStatus, row.status),
                activitys: _.isEmpty(filterActivitys) || _.includes(filterActivitys, row.atividade),
                groupService: _.isEmpty(filterGroupService) || _.includes(filterGroupService, row.grupo_servico),
                farm: _.isEmpty(filterFarm) || _.includes(filterFarm, row.fazenda),
                local: _.isEmpty(filterLocal) || _.includes(filterLocal, row.local),
                service: _.isEmpty(filterServices) || _.includes(filterServices, row.servico)
            };
            return _.every(_.pick(filters, propsToCheck));
        });
        const dataFiltered = createColumns(filteredRows);
        const dataFilteredSummary = createColumnsSummary(filteredRows);
        setAllData(dataFiltered.rows);
        setAllDataSummary(dataFilteredSummary);
        setPercentRealized(dataFiltered.percentRealizado);
    }

    function alterFilterFarm() {
        if (allDataOriginal !== null) {
            const filteredDataLocationFarm = allDataOriginal?.filter((obj) => obj.fazenda === filterFarm);
            const uniqueLocais = filteredDataLocationFarm?.reduce((acc, obj) => {
                acc[obj.local] = true;
                return acc;
            }, {});
            const newFilteredLocais = Object.keys(uniqueLocais);
            newFilteredLocais.sort((a, b) => a.localeCompare(b));

            setAllFilter((prevFilter) => ({ ...prevFilter, allLocal: newFilteredLocais }));
        }
    }

    function alterFilterGroupService() {
        if (allDataOriginal !== null) {
            const filteredDataService = allDataOriginal?.filter((obj) => filterGroupService.includes(obj.grupo_servico));
            const uniqueServices = filteredDataService?.reduce((acc, obj) => {
                acc[obj.servico] = true;
                return acc;
            }, {});
            const newFilteredServices = Object.keys(uniqueServices);
            newFilteredServices.sort((a, b) => a.localeCompare(b));

            setAllFilter((prevFilter) => ({ ...prevFilter, allServico: newFilteredServices }));
        }
    }

    const toggleDrawerForm = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(open);
    };

    const drawerFormContent = () => (
        <div className={css.list} role="presentation" onClick={toggleDrawerForm(false)} onKeyDown={toggleDrawerForm(false)}>
            <Grid
                container
                spacing={0}
                onClick={(event) => {
                    event.stopPropagation();
                }}
                style={{ padding: '25px' }}
            >
                <FormDrawer generateForm={generateForm} clearFilter={clearFilter} setSearch={setSearch} search={search} />
            </Grid>
        </div>
    );

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (dataMoPrevistaRealizada !== null) {
                dataMoPrevistaRealizada.data.forEach((obj) => {
                    obj.id = uuidv4();
                });
                setIsLoading(false);
                warehouseValues(dataMoPrevistaRealizada);
            }
        }, 50);
        return () => clearTimeout(timeoutId);
    }, []);
    console.log('allDataSummary', allDataSummary);
    useEffect(() => {
        if (
            filterStatus.length > 0 ||
            filterActivitys.length > 0 ||
            filterGroupService.length > 0 ||
            filterFarm.length > 0 ||
            filterServices.length > 0 ||
            (filterLocal.length > 0 && search)
        ) {
            return filterData(filterStatus, filterActivitys, filterGroupService, filterFarm, filterLocal);
        }

        const dataOrign = createColumns(allDataOriginal);
        const dataSummary = createColumnsSummary(allDataOriginal);
        setAllDataSummary(dataSummary);
        setAllFilter(createFilter(allDataOriginal));
        setAllData(dataOrign.rows);
        setPercentRealized(dataOrign.percentRealizado);
    }, [search, filterServices, filterGroupService]);

    useEffect(() => {
        alterFilterFarm();
    }, [filterFarm]);

    useEffect(() => {
        alterFilterGroupService();
    }, [filterGroupService]);

    return (
        <Container container>
            <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={2}>
                    <div>
                        <h1 style={{ color: '#015641' }}>Previsto x Realizado</h1>
                    </div>
                </Grid>
                <Grid item xs={1}>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px' }}>
                        <Buttom
                            label="Filtros"
                            onClick={toggleDrawerForm(true)}
                            marginRight={10}
                            icon={<FilterAltIcon />}
                            disabled={isLoading}
                        />
                    </div>
                </Grid>
                <Grid item md={4} container direction="row">
                    <Card>
                        <header>Realizada</header>
                        <main>
                            <h2>{filterServices.length > 0 && filterGroupService.length > 0 ? parseInt(percentRealized) + '%' : '0%'}</h2>
                        </main>
                    </Card>
                    <Card>
                        <header>Qtde prevista</header>
                        <main>
                            <h2>
                                {filterServices.length > 0 && filterGroupService.length > 0
                                    ? allDataSummary?.qtdePrevistaTotal?.toLocaleString('pt-BR')
                                    : '0'}
                            </h2>
                        </main>
                    </Card>
                    <Card>
                        <header>Qtde Realizada</header>
                        <main>
                            <h2>
                                {filterServices.length > 0 && filterGroupService.length > 0
                                    ? allDataSummary?.quantidadeTotal?.toLocaleString('pt-BR')
                                    : '0'}
                            </h2>
                        </main>
                    </Card>
                </Grid>
                <Grid item xs={2}>
                    <div>
                        <MultipleCheckbox
                            maxWidth="300px"
                            data={allFilter.allGroupService}
                            label="Grupo de serviço"
                            labelHelper={''}
                            onChange={setFilterGroupService}
                            value={filterGroupService}
                            disabled={false}
                        />
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div>
                        <MultipleCheckbox
                            maxWidth="250px"
                            data={allFilter.allServico}
                            label="Serviço"
                            labelHelper="Selecione ao menos um grupo de serviço"
                            onChange={setFilterServices}
                            value={filterServices}
                            disabled={filterGroupService.length <= 0 ? true : false}
                        />
                    </div>
                </Grid>
                <Grid item xs={1} container justify="flex-end" alignItems="center">
                    <Link to={`/?fazenda=${filterInitialFarm}&safra=${filterInitialHarvest}`}>
                        <HomeIcon style={{ color: '#015641', fontSize: '48px', marginTop: '15px' }} />
                    </Link>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    {isLoading ? (
                        <div
                            style={{
                                height: '80vh',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            className="datagrid-wrapper"
                        >
                            <Loading>Carregando</Loading>
                        </div>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {filterServices.length > 0 && filterGroupService.length > 0 ? (
                                    <div style={{ height: '80vh' }}>
                                        <Chart chartType="Bar" width="100%" height="84vh" data={allData} options={options} />
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={gifGrafico} alt="GIF animado" width={1000} />
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Drawer open={isDrawerOpen} onClose={toggleDrawerForm(false)} anchor="left">
                {drawerFormContent()}
            </Drawer>
        </Container>
    );
}
