import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import { useDispatch, useSelector } from 'react-redux';

export default function BarHorizontal({ allData }) {
    const [data, setData] = useState(allData);
    const options = {
        legend: { position: 'none' },
        chartArea: { width: '50%' },
        hAxis: {
            minValue: 0,
            maxValue: 100,
            format: "#'%'"
        },
        bars: 'horizontal'
    };

    function transformDataForChart(data) {
        const counts = {};
        data.forEach((obj) => {
            if (counts[obj.status]) {
                counts[obj.status]++;
            } else {
                counts[obj.status] = 1;
            }
        });

        const categories = [
            { name: 'Realizado', color: '#440540' },
            { name: 'Concluído', color: '#04580d' },
            { name: 'Concluído com atraso', color: '#e4ce08' },
            { name: 'Atrasado', color: '#ce0a00' },
            { name: 'Futuro', color: 'gray' },
            { name: 'Solicitado', color: '#00074f' },
            { name: 'Em andamento', color: '#c37d75' }
        ];

        const chartData = [['Status', '%', { role: 'style' }]];

        categories.forEach((cat) => {
            const count = counts[cat.name] || 0;
            const percent = parseFloat(((count / data.length) * 100).toFixed(2));
            chartData.push([cat.name, percent, cat.color]);
        });
        return chartData;
    }

    useEffect(() => {
        if (allData !== null) setData(transformDataForChart(allData.rows));
    }, [allData]);
    return data !== null && <Chart chartType="BarChart" width="100%" height="400px" data={data} options={options} />;
}
