import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';

export default function MultipleSelectCheckmarks({ minWidth, data, label, value, onChange, disabled, labelHelper, maxWidth, marginLeft }) {
    return (
        <div>
            <Grid container direction="column" justifyContent="flex-start">
                <FormControl style={{ maxWidth: maxWidth || 'none', minWidth: minWidth || 'none', marginLeft: marginLeft || 0 }}>
                    <InputLabel id="label">{label}</InputLabel>
                    <Select
                        disabled={disabled}
                        labelId="label"
                        id="multiple-checkbox"
                        multiple
                        value={value}
                        onChange={(event) => onChange(event.target.value)}
                        input={<OutlinedInput label={label} />}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return 'Selecione uma opção';
                            }
                            if (selected.length <= 3) {
                                return selected.join(', ');
                            }
                            return `${selected.slice(0, 3).join(', ')} +${selected.length - 3}`;
                        }}
                    >
                        {data?.map((val, key) => (
                            <MenuItem key={key} value={val}>
                                <Checkbox checked={value.indexOf(val) > -1} />
                                <ListItemText primary={val} />
                            </MenuItem>
                        ))}
                    </Select>
                    {disabled && <p style={{ fontSize: '10px' }}>{labelHelper}</p>}
                </FormControl>
            </Grid>
        </div>
    );
}
