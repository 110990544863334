import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

import { Grid } from '@material-ui/core';
import ReplayIcon from '@mui/icons-material/Replay';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { RiSeedlingLine } from 'react-icons/ri';
import { AiOutlineSchedule } from 'react-icons/ai';
import { GiFarmer } from 'react-icons/gi';
import { GoGraph } from 'react-icons/go';
import { SlGraph } from 'react-icons/sl';

import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { Main, ButtonStyled, LinkStyled, Header, LoadingButtonStyled, ProgressBarStyled, Footer } from './styles';
import {
    getMoPreviewRealized,
    getFertilizing,
    getMoMa,
    getAgendaMacro,
    getCaracteristica,
    updateCache,
    getHomeInfo
} from '../../services/bi';
import LogoSgAgri from '../../assets/images/logo.png';
import './styles.css';

const Home = ({ fazenda, safra }) => {
    const [isLoadingAdubacao, setIsLoadingAdubacao] = useState(true);
    const [isLoadingAgendaMacro, setIsLoadingAgendaMacro] = useState(true);
    const [isLoadingMoPrevistaRealizada, setIsLoadingMoPrevistaRealizada] = useState(true);
    const [isLoadingMoMa, setIsLoadingMoMa] = useState(true);
    const [isLoadingCaracteristica, setIsLoadingCaracteristica] = useState(true);
    const [isLoadingCache, setIsLoadingCache] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [progress, setProgress] = useState(0);

    const [homeInfo, setHomeInfo] = useState({});

    const serializedStateAdubacao = localStorage.getItem('dataAdubacao');
    const serializedStateAgendaMacro = localStorage.getItem('dataAgendaMacro');
    const serializedStateCaracteristica = localStorage.getItem('dataCaracteristica');
    const serializedStateMoMa = localStorage.getItem('dataMoMa');
    const serializedStateMoPrevistaRealizada = localStorage.getItem('dataMoPrevistaRealizada');
    const serializedFarm = localStorage.getItem('farm');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function updateLocalStoage() {
        setProgress(0);
        setIsLoadingCache(!isLoadingCache);
        setIsLoadingAdubacao(true);
        setIsLoadingAgendaMacro(true);
        setIsLoadingMoPrevistaRealizada(true);
        setIsLoadingMoMa(true);
        setIsLoadingCaracteristica(true);

        localStorage.removeItem('dataAdubacao');
        localStorage.removeItem('dataAgendaMacro');
        localStorage.removeItem('dataMoMa');
        localStorage.removeItem('dataCaracteristica');
        localStorage.removeItem('dataMoPrevistaRealizada');
        localStorage.removeItem('farm');
        localStorage.removeItem('harvest');

        updateCache(fazenda, safra).then((resp) => {
            if (resp.data[0]?.resultado === 0) {
                setTimeout(() => {
                    setOpenSnackbar(true);
                }, 2500);
                setTimeout(() => {
                    setOpenSnackbar(false);
                }, 9000);
            }
        });

        getHomeInfo(fazenda, safra).then((resp) => {
            const dataCache = resp?.data[0]?.dta_cache;
            const dataFormatada = format(new Date(dataCache), 'dd/MM/yyyy HH:mm:ss');

            const homeInfo = {
                date: dataFormatada,
                nameFarm: resp?.data[0]?.nome_razao
            };

            setHomeInfo(homeInfo);
        });
    }

    if (progress === 200) {
        setProgress(100);
    }

    useEffect(() => {
        getHomeInfo(fazenda, safra).then((resp) => {
            const dataCache = resp?.data[0]?.dta_cache;
            const dataFormatada = format(new Date(dataCache), 'dd/MM/yyyy HH:mm:ss');

            const homeInfo = {
                date: dataFormatada,
                nameFarm: resp?.data[0]?.nome_razao
            };

            setHomeInfo(homeInfo);
        });
        let totalRequests = 4;
        let progressConcluded = 100 / totalRequests;

        if (serializedStateAdubacao) {
            setIsLoadingAdubacao(false);
            setProgress((progress) => progress + progressConcluded);
        } else {
            getFertilizing(fazenda, safra).then((resp) => {
                dispatch({ type: 'SET_ALLDATA_ADUBACAO', payload: resp });
                setIsLoadingAdubacao(false);
                setProgress((progress) => progress + progressConcluded);
            });
        }

        if (serializedStateAgendaMacro) {
            setIsLoadingAgendaMacro(false);
            setProgress((progress) => progress + progressConcluded);
        } else {
            getAgendaMacro(fazenda, safra).then((resp) => {
                dispatch({ type: 'SET_ALLDATA_AGENDA_MACRO', payload: resp });
                setIsLoadingAgendaMacro(false);
                setProgress((progress) => progress + progressConcluded);
            });
        }

        if (serializedStateCaracteristica) {
            setIsLoadingCaracteristica(false);
            setProgress((progress) => progress + progressConcluded);
        } else {
            getCaracteristica(fazenda, safra).then((resp) => {
                dispatch({ type: 'SET_ALLDATA_CARACTERISTICA', payload: resp });
                setIsLoadingCaracteristica(false);
                setProgress((progress) => progress + progressConcluded);
            });
        }

        if (serializedStateMoMa) {
            setIsLoadingMoMa(false);
            setProgress((progress) => progress + progressConcluded);
        } else {
            getMoMa(fazenda, safra).then((resp) => {
                dispatch({ type: 'SET_ALLDATA_MO_MA', payload: resp });
                setIsLoadingMoMa(false);
                setProgress((progress) => progress + progressConcluded);
            });
        }

        if (serializedStateMoPrevistaRealizada) {
            setIsLoadingMoPrevistaRealizada(false);
            setProgress((progress) => progress + progressConcluded);
        } else {
            getMoPreviewRealized(fazenda, safra).then((resp) => {
                dispatch({ type: 'SET_ALLDATA_MO_PREVISTA_REALIZADA', payload: resp });
                setIsLoadingMoPrevistaRealizada(false);
                setProgress((progress) => progress + progressConcluded);
            });
        }

        let date = new Date();
        let dateFormated = date.toLocaleString('pt-BR');
        localStorage.setItem('lastUpdate', dateFormated);
        localStorage.setItem('farm', fazenda);
        localStorage.setItem('harvest', safra);
    }, [isLoadingCache]);

    useEffect(() => {
        if (serializedFarm !== fazenda) {
            updateLocalStoage();
        }
    }, [fazenda]);

    return (
        <>
            <Header>
                <img src={LogoSgAgri} alt="logo" width={250} />
                <h1>{homeInfo?.nameFarm}</h1>
                <div>
                    <LoadingButtonStyled
                        loading={progress < 100}
                        loadingPosition="start"
                        startIcon={<ReplayIcon />}
                        variant="outlined"
                        onClick={updateLocalStoage}
                    >
                        Atualizar dados
                    </LoadingButtonStyled>
                    <p>Última atualização: {homeInfo?.date}</p>
                </div>
            </Header>
            <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <SlGraph color="#015641" size="10rem" />
            </Grid>
            <Main>
                <ButtonStyled
                    disabled={isLoadingAdubacao}
                    endIcon={<RiSeedlingLine />}
                    onClick={() => navigate(`/adubacao/?fazenda=${fazenda}&safra=${safra}`)}
                >
                    Adubação
                </ButtonStyled>
                <ButtonStyled
                    disabled={isLoadingAgendaMacro}
                    endIcon={<AiOutlineSchedule />}
                    onClick={() => navigate(`/agenda-macro/?fazenda=${fazenda}&safra=${safra}`)}
                >
                    Agenda Macro
                </ButtonStyled>

                <ButtonStyled
                    disabled={isLoadingMoPrevistaRealizada}
                    endIcon={<GiFarmer />}
                    onClick={() => navigate(`/mo-ma/?fazenda=${fazenda}&safra=${safra}`)}
                >
                    Mão de obra e máquina
                </ButtonStyled>

                <ButtonStyled
                    disabled={isLoadingMoMa}
                    endIcon={<GoGraph />}
                    onClick={() => navigate(`/mo-prevista-realizada/?fazenda=${fazenda}&safra=${safra}`)}
                >
                    Prevista x Realizada
                </ButtonStyled>
                <ButtonStyled
                    disabled={isLoadingCaracteristica}
                    endIcon={<GoGraph />}
                    onClick={() => navigate(`/caracteristica/?fazenda=${fazenda}&safra=${safra}`)}
                >
                    Característica
                </ButtonStyled>
            </Main>
            <Footer>
                <div className={progress >= 100 ? 'fade-out' : ''}>
                    <p>Carregando...</p>
                    <ProgressBarStyled completed={progress} bgColor="#018f6e" />
                </div>
            </Footer>
            <Snackbar open={openSnackbar} autoHideDuration={6000}>
                <MuiAlert elevation={6} variant="filled" severity="success">
                    Dados atualizados com sucesso!
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default Home;
